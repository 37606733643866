
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TagDetail"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Tag"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"content"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"articles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"20"}}]}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionInfo"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"edges"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ArticleDigest"},"directives":[]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"oss"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"boost"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"score"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"remark"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deleted"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":294}};
    doc.loc.source = {"body":"#import \"../connection.gql\"\n#import \"../article/digest.gql\"\n\nfragment TagDetail on Tag {\n  id\n  content\n  createdAt\n  articles(input: { first: 20 }) {\n    ...ConnectionInfo\n    edges {\n      node {\n        ...ArticleDigest\n      }\n    }\n  }\n  oss {\n    boost\n    score\n  }\n  remark\n  deleted\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../connection.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../article/digest.gql").definitions));


      module.exports = doc;
    
